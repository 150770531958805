import React, { useEffect, useLayoutEffect, useRef } from 'react';
import Modal from 'react-modal';
import numeral from 'numeral';
import debounce from 'lodash/debounce';
import isString from 'lodash/isString';
import { useLocation } from 'react-router-dom';
import includes from 'lodash/includes';
import split from 'lodash/split';

import { DesktopLayout } from '@features/app/layout/desktop';
import { MobileLayout } from '@features/app/layout/mobile';
import services, { useServices } from '@features/core/services';
import { PageName } from '@features/core/routing';

import CustomEvents, { MarketingEvents } from '@packages/events/appEvents';

import {
  ACCEPTED_COOKIE,
  CUSTOM_TIME_ZONE,
  IS_WEB_VIEW,
  FWD_DEST_COOKIE,
  LANGUAGE,
  IS_NATIVE_VIEW,
  SHOULD_SET_LIMITS,
} from '@common/constants/cookie';
import {
  ENV,
  HAS_COOKIE_CONSENT_TYPE,
  HAS_HOME_PAGE,
} from '@common/constants/config';
import { ACCEPT_COOKIE_LAYER } from '@common/constants/dialogs';
import { isNativeApp, isWebView } from '@common/helpers/links';
import betsUpdaterConnect, {
  connect,
} from '@common/helpers/cashoutHelper/betsUpdater';
import {
  changeLanguage,
  getLangFromUrl,
  isDesktopView,
  createExpiresStamp,
} from '@common/helpers/deviceUtil';
import {
  setLoggedInStatus,
  useUserState,
} from '@common/providers/user/useUserState';
import {
  setSelectedEvent,
  useEventsListState,
} from '@common/providers/events/eventList/useEventsList';
import { openSidebar } from '@common/providers/application/useAppState';
import { getIframeBetValidationAction } from '@common/providers/account/helper';
import {
  setCurrentWebRoute,
  useRouterState,
} from '@common/providers/router/useRouterState';
import { initialize } from '@common/helpers/errorLogger';
import {
  getDefaultRouteName,
  isMatchWebRoutes,
  setLocation,
} from '@common/providers/router/helper';
import matchWebRoutes from '@common/helpers/matchWebRoutes';
import useLugasActiveTimer from '@common/hooks/useLugasActiveTimer';
import useDisableAnimation from '@common/hooks/useDisableAnimation';
import useVisibilityChange from '@common/hooks/useVisibilityChange';
import { isIframeUser } from '@common/helpers/userHelper/userHelper';
import { UserLoadingState } from '@common/providers/user/actions/actionTypes';
import { useChangePassword } from '@common/hooks/useChangePassword';

Modal.setAppElement('#root');

const App = (): JSX.Element => {
  useRouterState(s => s.currentWebRoute); // needed for subscription
  const data = useUserState(state => state.data);
  const location = useRouterState(s => s.location);
  const selectedEvent = useEventsListState(state => state.detail.selectedEvent);
  const { cookie, domainLang } = useServices();
  const { search, pathname } = useLocation();
  const urlParams = new URLSearchParams(search);
  const signature = urlParams.get('signature');
  const player_id = urlParams.get('player_id');
  const affiliate_id = urlParams.get('affiliate_id');
  const isIframe = isIframeUser(search);
  const shouldSetLimits = services.cookie.get(SHOULD_SET_LIMITS);

  useLugasActiveTimer();
  useDisableAnimation();
  useVisibilityChange();
  useChangePassword(true);

  window.addEventListener('online', () => {
    if (services.config.get(ENV) !== 'development') {
      window.location.reload();
    }
  });

  useEffect(() => {
    if (
      services.config.get(HAS_COOKIE_CONSENT_TYPE) === 'internal' &&
      !includes(document.cookie, ACCEPTED_COOKIE) &&
      !isWebView(search) &&
      !isNativeApp(search)
    ) {
      openSidebar({ type: ACCEPT_COOKIE_LAYER });
    }
  }, []);

  useEffect(() => {
    services.events.emitEvent(MarketingEvents.PAGE_VIEW, location);
  }, [location]);

  useEffect(() => {
    const webRoute = matchWebRoutes(pathname, split(search, '?')[1]);
    setCurrentWebRoute(webRoute);
  }, [pathname, search]);

  const queryId = urlParams.get('events');
  useEffect(() => {
    if (queryId && !selectedEvent) {
      setSelectedEvent({ eventId: queryId });
    }
  }, [search]);

  useEffect(() => {
    if (data) {
      betsUpdaterConnect(connect);
    }
  }, [data]);

  useEffect(() => {
    if (shouldSetLimits && pathname !== PageName.SET_LIMITS) {
      setLocation(PageName.SET_LIMITS);
    }
  }, [shouldSetLimits, pathname]);

  const prevWidth = useRef<number>(0);

  const handlleOnResize = (): void => {
    if (
      isDesktopView() &&
      !/payin|payout|register|forgot-password|login|change-password/.test(
        window.location.href,
      ) &&
      services.config.get(ENV) !== 'development'
    ) {
      window.location.reload();
    }
  };

  const onMessage = (message): void => {
    try {
      if (isString(message.data)) {
        const { type, body } = JSON.parse(message.data);
        if (CustomEvents[type]) {
          services.events.emitEvent(type, body, true);
        }
      }
    } catch (e) {
      services.logger.log(`Not able to parse event ${e}`);
    }
  };

  const eventListener = (): void => {
    document.addEventListener('message', onMessage);
    window.addEventListener('message', onMessage);
  };

  useEffect(() => {
    prevWidth.current = window.innerWidth;
    window.addEventListener('resize', debounce(handlleOnResize, 500));
    if (cookie.get(LANGUAGE) !== getLangFromUrl()) {
      changeLanguage(cookie.get(LANGUAGE));
    }
    if (isWebView(search)) {
      cookie.set(IS_WEB_VIEW, IS_WEB_VIEW, {
        expires: createExpiresStamp(60 * 60 * 24 * 365),
      });
    }
    if (isNativeApp(search)) {
      cookie.set(IS_NATIVE_VIEW, IS_NATIVE_VIEW, {
        expires: createExpiresStamp(60 * 60 * 24 * 365),
      });
    }

    eventListener();
    initialize();

    const gmt = urlParams.get('gmt');
    const fwdDestination = urlParams.get('fwd_destination');

    if (gmt) {
      services.cookie.set(CUSTOM_TIME_ZONE, gmt);
    }
    if (fwdDestination) {
      services.cookie.set(FWD_DEST_COOKIE, fwdDestination);
    }
  }, []);

  useLayoutEffect(() => {
    if (
      !services.config.get(HAS_HOME_PAGE) &&
      isMatchWebRoutes(PageName.HOME) &&
      !isIframe
    ) {
      setLocation(getDefaultRouteName());
    }
  }, [pathname, signature]);

  useEffect(() => {
    if (isIframe && signature && player_id && affiliate_id) {
      setLoggedInStatus(UserLoadingState.VERIFYING);
      getIframeBetValidationAction({
        signature,
        player_id,
        affiliate_id,
      });
      setLocation(PageName.LIVE);
    }
  }, [signature, player_id, affiliate_id]);

  numeral.locale(domainLang);
  return isDesktopView() ? <DesktopLayout /> : <MobileLayout />;
};

export default App;
