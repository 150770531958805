import { loadConfig, configTypes } from '@features/core/loadConfig';

import { BETTINGSLIP_DEFAULT_STATE } from '@common/constants/config';
import {
  IBettingslip,
  IBettingslipStates,
  IBettingslipType,
} from '@common/interfaces';
import { IUser } from '@common/interfaces/user/IUser';

const userDefaultData = loadConfig(
  configTypes.bs,
  BETTINGSLIP_DEFAULT_STATE,
) as IUser;

export const STATE_OPENED = IBettingslipStates.OPENED;
export const STATE_ACCEPTED = IBettingslipStates.ACCEPTED;
export const STATE_QUEUED = IBettingslipStates.QUEUED;
export const STATE_REJECTED = IBettingslipStates.REJECTED;
export const STATE_FROZEN = IBettingslipStates.FROZEN;
export const IMPORT_BETTINGSLIP = 'import_bettingslip';

export type BsState = 'opened' | 'accepted' | 'queued' | 'rejected';

export const defaultState: IBettingslip = {
  betPackerPredictions: [],
  betPackerSelections: {},
  selections: {},
  state: STATE_OPENED,
  loading: false,
  banks: [],
  user: userDefaultData,
  size: [1],
  totalStake: 10,
  stake: 10,
  type: IBettingslipType.single,
  error: null,
  bet_id: null,
  queue_delay: 0,
  bsMode: 'normal',
  betPackerOdds: null,
  legsCount: 0,
  selectionsLoading: false,
};
