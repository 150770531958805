import map from 'lodash/map';
import includes from 'lodash/includes';
import { AxiosRequestConfig } from 'axios';
import get from 'lodash/get';

import axios from '@features/core/axios';
import services from '@features/core/services';
import i18n from '@features/core/translation';
import CustomError, {
  NEWORK_ERROR,
  convertResponseToError,
} from '@features/core/error/error';
import { PageName } from '@features/core/routing';

import { IApiUrlInfo, ICustomError, IResponseError } from '@common/interfaces';
import { StaticCalls, DynamicCalls, getApiUrl } from '@common/api/api';
import logoutUser from '@common/providers/user/actions/logoutUser';
import { setLocation } from '@common/providers/router/helper';

const { API_SUBMIT_PAYMENT, API_SUPPORT } = DynamicCalls;
const { API_LOGIN, API_LOGIN_APP } = StaticCalls;

export enum MethodType {
  Get = 'get',
  Post = 'post',
  Put = 'put',
  Patch = 'patch',
}

const parseResponseData = (str): { error: { code: string } } => {
  try {
    return JSON.parse(str);
  } catch (err) {
    return str;
  }
};

const isNotAuthorized = (response): boolean => {
  const isNotAuthorizedError =
    response.data?.error?.exceptionClass ===
    'Model::Exception::Action::NotAuthorized';

  return (
    get(response.config.headers, 'TOKEN') &&
    response.data.error &&
    isNotAuthorizedError
  );
};

export const apiHandler = async <T>(
  config: AxiosRequestConfig,
  apiUrlInfo?: IApiUrlInfo,
): Promise<T & ICustomError> => {
  try {
    const response = await services.http.request({
      url: getApiUrl(apiUrlInfo?.apiUrl, apiUrlInfo?.apiData),
      ...config,
    });
    const data = response?.data;

    if (isNotAuthorized(response)) {
      await logoutUser();
      setLocation(PageName.LOGIN);
    }

    if (data?.error) {
      return new CustomError({
        message: convertResponseToError(data.error as IResponseError),
        code: data.error?.code,
        data: data.result,
      }) as T & ICustomError;
    }

    if (data?.errors) {
      return new CustomError({
        message: '',
        messages: map(data.errors, error =>
          convertResponseToError(error as IResponseError),
        ),
      }) as T & ICustomError;
    }

    return data;
  } catch (e) {
    const customAxiosError = e as IResponseError;
    if (axios.isCancel(e) || customAxiosError.message === NEWORK_ERROR) {
      return new CustomError({
        message: customAxiosError.message as string,
        code: '600',
      }) as T & ICustomError;
    }

    if (customAxiosError.response?.status === 403) {
      return new CustomError({
        message: i18n.t('common.labels.defaultError'),
        code: '403',
      }) as T & ICustomError;
    }
    if (
      includes(
        [API_LOGIN, API_LOGIN_APP, API_SUBMIT_PAYMENT, API_SUPPORT],
        apiUrlInfo?.apiUrl,
      )
    ) {
      return new CustomError({
        message: convertResponseToError(customAxiosError.response?.data),
        code:
          parseResponseData(customAxiosError.response?.data)?.error?.code ||
          customAxiosError.response?.data?.code ||
          customAxiosError.response?.status,
        data: parseResponseData(customAxiosError.response?.data),
      }) as T & ICustomError;
    }

    return new CustomError({
      message:
        (customAxiosError?.message as string) ||
        i18n.t('common.labels.defaultError'),
      code: String(customAxiosError.response?.status),
    }) as T & ICustomError;
  }
};
