export const TOKEN = 'token'; // user token is used for getting access to api
export const SESSION_ID = 'session_id'; // session id is used for getting access to api
export const USER_ID = 'user_id'; // connects to bet rm, can be removed
export const MAIN_USER_ID = 'main_user_id'; // connects to bet rm, can be removed
export const IS_LOGGED_IN = 'is_logged_in'; // used for nginx cache
export const AUTH_TOKEN = 'auth_token'; // used for redirect from native app
export const QUERY_REG_DATA = 'data'; // ussed for sigleForm registration
export const AFFILIATE_ID = 'affiliate_id';

export const ACCEPT_TAC_LAYER_COOKIE = 'a_tac_layer'; // used for showing accept term layer
export const SUMMARY_LAYER_COOKIE = 's_layer'; // used for showing summary layer
export const RESEND_CONFIRMATION = 'rs_con'; // resend confirmation email
export const BETTING_SLIP = 'betting_slip'; // used for reload bettinglsip
export const SELECTIONS = 'selections'; // used for saving selections
export const STEP_COOKIE = 'reg-step'; // current registration step for saving registration progress
export const DATA_COOKIE = 'reg-data'; // current registration prefilled fields data
export const APP_FONT = 'app_font';
export const APP_THEME = 'app_theme';
export const APP_THEME_COLORS = 'app_theme_colors';
export const DIALOGS = 'dialogs';
export const DEPOSIT_LIMIT_LAYER_COOKIE = 'dep-l-l-c';
export const APP_REFRESHED = 'appRefreshed';
export const LAST_DEPOSIT_METHOD = 'last_deposit_method'; // we save this cookie to set last deposit method as default
export const LAST_WITHDRAWAL_METHOD = 'last_withdrawal_method'; // we save this cookie to set last deposit method as default

// Lugas related cookies
export const LUGAS_TIMER = 'l_timer'; // starts after first bet and count time
export const REALITY_TIMER = 'r_timer'; // starts after first bet and  show reality layer once per 1 hr
export const IN_ACTIVITY_TIMER = 'in_a_timer'; // starts on mouse click and reload app if user is not clicking for 30 minutes

export const CHANGE_PASSWORD = 'ch_p'; // used to urge user to change password

export const DISABLE_ANIMATION = 'dis_an'; // used to disable animation

export const FAVORITES_EVENTS_COOKIE = 'fav_e'; // favorite events for not logged in user NOT USED
export const FAVORITES_CASHOUT_COOKIE = 'fav_cash'; // favorite events for not logged in user NOT USED
export const FAVORITES_CATEGORIES_COOKIE = 'fav_c'; //  favorite categories for not logged in user USED
export const FAVORITES_RESULTS_COOKIE = 'fav_r'; // favorite results for not logged in user NOT USED
export const LANGUAGE = 'lang'; // selected language
export const OMIT_FACE_ID_CHECK = 'omit_face_id_check'; // omit face id check, used for webview
export const VERIFICATION_NOTIFICATION_REMOVE = 'verif_notif_remove'; // used if user clicks to remove verification notification button
export const YEAR_VERIFICATION_NOTIFICATION_REMOVE = 'y_verif_notif_remove'; // used if user clicks to remove verification notification button
export const ONLINE_ACCOUNT_NOTICE_REMOVE = 'online_acc_notic_remove'; // used if user clicks to remove online account notice button
export const EVENT_SORTING_KEY = 'event_sorting_key';
export const DEFAULT_WALLET_ID = 'default_wallet_id'; // used for setting default wallet
export const SWITCHER_POPUP_CHECKED = 'switcher_popup_checked'; // if true, popup view will not opened after switch wallet
export const IS_TOP_ADV_BANNER_CLOSE = 'close_app_add'; // used for hiding mobile application advertisement
export const IS_YEARLY_DATA_CONFIRMED = 'is_y_data_conf'; // used for hiding mobile application advertisement
export const IS_WEB_VIEW = 'is_w_view'; // used for hiding mobile application advertisement
export const IS_NATIVE_VIEW = 'is_native_view';
export const IS_AUTOLOGIN_ENABLED = 'is_auto_login_enabled'; // enabling autologin with biometrics data
export const AUTO_LOGIN_ENABLED = 'AUTO_LOGIN_ENABLED'; // used for enabling autologin with biometric data
export const AUTO_LOGIN_DISABLED = 'AUTO_LOGIN_DISABLED'; // used for disabling autologin with biometric data
export const HAS_CAMERA_PERMISSION = 'has_camera_permission';
export const CAMERA_PERMISSION_GRANTED = 'camera_permission_granted';
export const CAMERA_PERMISSION_DENIED = 'camera_permission_denied';
export const HIDE_PUSH_NOTIFICATION_LAYER = 'hide_push';
export const CUSTOM_TIME_ZONE = 'custom_time_zone';
export const ACCEPTED_COOKIE = 'consent_cookie';
export const GCMS_FORM_COOKIE = 'gcms_form_cookie';
export const FWD_DEST_COOKIE = 'fwd_destination';
export const SHOULD_SET_LIMITS = 'should_set_limits';
