import React, { useMemo } from 'react';
import includes from 'lodash/includes';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { useEventsModel } from '@features/events/useEventsModel';
import services from '@features/core/services';

import { MarketingEvents } from '@packages/events/appEvents';

import { MAX_BS_SELECTIONS } from '@common/constants/config';
import { PageType } from '@common/interfaces';
import {
  isMarketEnabled,
  containsDisabledPredictions,
} from '@common/helpers/markets/marketModel';
import {
  getFormattedOdds,
  isEnabled,
  getShortLabel,
  createStructuredData,
  extractIdentifiers,
} from '@common/helpers/eventsHelper/predictionModel';
import {
  getLiveStatus,
  isWinner,
} from '@common/helpers/eventsHelper/eventStatusHelper';
import {
  STATE_FROZEN,
  STATE_QUEUED,
} from '@common/providers/bettingslip/state';
import { BetView } from '@common/providers/bettingslip/types';
import {
  deleteSelectionData,
  setSelectionData,
  useEventsListState,
} from '@common/providers/events/eventList/useEventsList';
import {
  addBetPackerSelection,
  addSelection,
  removeBetPackerSelection,
  removeSelection,
  useBettingSlip,
} from '@common/providers/bettingslip/useBettingSlip';
import useOddsDirection from '@common/hooks/useOddsDirection';
import { getSelectionsCount } from '@common/providers/bettingslip/helper';

import { Icon } from '@ui/components/icon';

import { Props } from './Prediction.types';
import * as S from './Prediction.styled';

const Prediction = (props: Props): React.ReactElement | null => {
  const { t } = useTranslation();
  const { selectTextColor } = useTheme();
  const {
    eventId,
    marketId,
    predictionId,
    listType,
    pageType,
    withLabel,
  } = props;

  const market = useEventsListState(s => s[listType].data.markets[marketId]);
  const prediction = useEventsListState(
    s => s[listType].data.predictions[predictionId],
  );
  const bsMode = useBettingSlip(state => state.bsMode);
  const state = useBettingSlip(s => s.state);
  const betPackerPredictions = useBettingSlip(s => s.betPackerPredictions);
  const betPackerSelections = useBettingSlip(s => s.betPackerSelections);
  const selections = useBettingSlip(s => s.selections);
  const loading = useBettingSlip(s => s.loading);
  const { event, category } = useEventsModel(eventId, listType);
  const { up, down } = useOddsDirection(prediction);
  const selected = !!selections[prediction.id];
  const kombiSelected = !!betPackerSelections[prediction.id];

  const toggleSelection = React.useCallback(
    (ev): void | null | boolean => {
      ev.preventDefault();
      ev.stopPropagation();
      if (state === STATE_FROZEN || state === STATE_QUEUED || loading) {
        return false;
      }
      const selectionData = createStructuredData({
        prediction,
        category,
        market,
        event,
      });
      const metaData = extractIdentifiers({
        prediction,
        category,
        market,
        event,
      });
      if (
        getSelectionsCount(selections) >=
          (services.config.get(MAX_BS_SELECTIONS) as number) &&
        !selected
      ) {
        return null;
      }
      if (bsMode === BetView.BETPACKER) {
        if (kombiSelected) {
          return removeBetPackerSelection(metaData);
        }
        return addBetPackerSelection(metaData);
      }
      if (selected) {
        removeSelection(metaData);
        deleteSelectionData(metaData);
        return null;
      }

      services.events.emitEvent(MarketingEvents.SELECTION, {
        listType,
        ...prediction,
        category,
        event,
      });
      // important to set selections data first
      setSelectionData(selectionData);
      addSelection(metaData);
      return null;
    },
    [
      state,
      loading,
      prediction,
      category,
      market,
      event,
      selections,
      selected,
      bsMode,
      kombiSelected,
    ],
  );

  const formattedOdds = useMemo(() => getFormattedOdds(prediction), [
    prediction,
  ]);
  const label = useMemo(() => getShortLabel(prediction, market.type), [
    prediction,
    market.type,
  ]);
  const liveStatus = useMemo(() => getLiveStatus(event), [event]);
  const hasWinner = useMemo(() => !!isWinner(event), [event]);

  if (!prediction) {
    return null;
  }

  if (
    !isEnabled(prediction) ||
    !isMarketEnabled(market, liveStatus) ||
    (bsMode === BetView.BETPACKER &&
      !includes(betPackerPredictions, prediction.id) &&
      !kombiSelected)
  ) {
    if (!containsDisabledPredictions(market)) {
      return null;
    }
    return (
      <S.StyledButton
        isDisabled
        aria-label={t('common.buttons.prediction_close')}
        className="disabled"
        isDetail={pageType === PageType.DETAIL}
      >
        {pageType === PageType.DETAIL && label}
        <Icon
          width="23"
          height="13.6"
          name="lock-outlined"
          fill={selectTextColor}
        />
      </S.StyledButton>
    );
  }

  const selectedInMode =
    bsMode === BetView.BETPACKER ? kombiSelected : selected;

  return (
    <S.StyledButton
      selected={selectedInMode}
      up={up}
      down={down}
      onClick={toggleSelection}
      className={`${formattedOdds.length > 5 ? 'small' : ''}${
        selected ? 'selected' : ''
      } ${label.length > 3 ? 'long-label' : ''}`}
      hasWinner={hasWinner}
      aria-label={`Prediction ${formattedOdds}`}
      data-qa={`prediction-btn-${liveStatus}`}
      isDetail={pageType === PageType.DETAIL}
      translate="no"
    >
      {(pageType === PageType.DETAIL || withLabel) && (
        <S.PredictionsLabel translate="no" selected={selectedInMode}>
          {label}
        </S.PredictionsLabel>
      )}
      <span translate="no">{formattedOdds}</span>
    </S.StyledButton>
  );
};

export default React.memo(Prediction);
