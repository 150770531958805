/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createWithEqualityFn } from 'zustand/traditional';
import filter from 'lodash/filter';
import map from 'lodash/map';
import { shallow } from 'zustand/shallow';
import values from 'lodash/values';

import CustomError from '@features/core/error/error';
import services from '@features/core/services';

import { MarketingEvents, BetEvents } from '@packages/events/appEvents';

import {
  IBettingslip,
  IBettingslipStates,
  IBettingslipType,
  IQueuedBettingslip,
  ISubmittedBettingslip,
} from '@common/interfaces';
import {
  STATE_ACCEPTED,
  STATE_OPENED,
  STATE_QUEUED,
  STATE_REJECTED,
  defaultState,
} from '@common/providers/bettingslip/state';
import {
  BettingSlipActions,
  handleSubmitBettingSlipError,
  makeBS,
  partitionSelections,
} from '@common/providers/bettingslip/helper';
import { calculateStake } from '@common/helpers/bettingSlipHelper/bettingSlipCalculationModel';
import {
  BetView,
  IBetPackerOdds,
  ISetBettingSlipErrorPayload,
  ISetBettingSlipSuccessPayload,
} from '@common/providers/bettingslip/types';
import {
  IBSWalletType,
  IBetPackerType,
  ISelections,
} from '@common/interfaces/bettingslip/IBettingslip';
import postBettingslip from '@common/api/bettingslip/postBettingslip';
import fetchBetPackerData from '@common/api/bettingslip/getBetPacker';
import { ISelection } from '@common/interfaces/prediction/IPrediction';
import { deleteSelectionData } from '@common/providers/events/eventList/useEventsList';

export const useBettingSlip = createWithEqualityFn<IBettingslip>(
  () => defaultState,
  shallow,
);

export const addSelection = (payload: ISelection) => {
  useBettingSlip.setState(state => {
    return makeBS(
      state,
      {
        ...state,
        selections: {
          ...state.selections,
          [payload.id]: {
            ...payload,
            timeAdded: Date.now().toString(),
          },
        },
        banks: [],
        size: state.type === IBettingslipType.system ? [] : state.size,
      },
      [],
    );
  });
};

export const removeSelection = (payload: ISelection) => {
  useBettingSlip.setState(state => {
    const newSelections = { ...state.selections };
    delete newSelections[payload.id];

    return makeBS(
      state,
      {
        ...state,
        selections: newSelections,
        banks: [],
        size: state.type === IBettingslipType.system ? [] : state.size,
      },
      [],
      BettingSlipActions.REMOVE_SELECTION,
      payload,
    );
  });
};

export const setSelections = (newSelections: ISelections) => {
  useBettingSlip.setState(state => {
    return {
      ...state,
      selections: newSelections,
      banks: [],
      size: state.type === IBettingslipType.system ? [] : state.size,
    };
  });
};

export const resetSelections = () => {
  useBettingSlip.setState(() => ({
    selections: {},
  }));
};

export const setSelectionsLoading = (loading: boolean) => {
  useBettingSlip.setState(() => ({
    selectionsLoading: loading,
  }));
};

export const removeDisabledSelections = () => {
  const state = useBettingSlip.getState();

  const { enabled, disabled } = partitionSelections(state.selections);

  useBettingSlip.setState(() => {
    return makeBS(
      state,
      {
        ...state,
        banks: [],
        selections: enabled,
        betPackerSelections: partitionSelections(state.betPackerSelections)
          .enabled,
      },
      [],
    );
  });

  deleteSelectionData(values(disabled));
};

export const changeBettingSlipState = (payload: IBettingslipStates) => {
  useBettingSlip.setState(() => ({
    state: payload,
  }));
};

export const addBankEvent = (payload: number) => {
  useBettingSlip.setState(state => {
    return makeBS(
      state,
      {
        ...state,
        banks: state.banks.concat(payload),
      },
      [1],
      BettingSlipActions.ADD_BANK_EVENT,
    );
  });
};

export const removeBankEvent = (payload: number) => {
  useBettingSlip.setState(state => {
    return makeBS(
      state,
      {
        ...state,
        banks: filter(state.banks, bank => bank !== payload),
      },
      [1],
      BettingSlipActions.REMOVE_BANK_EVENT,
    );
  });
};

export const setSize = (payload: number[]) => {
  useBettingSlip.setState(state => {
    return makeBS(state, state, payload);
  });
};

export const setStake = (payload: { amount: string; isTotal?: boolean }) => {
  useBettingSlip.setState(state => {
    return {
      ...state,
      ...calculateStake(state, payload.amount, !!payload.isTotal),
    };
  });
};

export const setBettingSlipType = (payload: IBettingslipType) => {
  useBettingSlip.setState(state => {
    return makeBS(
      state,
      {
        ...state,
        type: payload,
        banks: [],
      },
      [1],
      BettingSlipActions.SET_BETTING_SLIP_TYPE,
    );
  });
};

export const setBettingSlipSuccess = (
  payload: ISetBettingSlipSuccessPayload,
) => {
  useBettingSlip.setState(state => {
    let bsState = STATE_OPENED;
    if (payload.is_accepted) {
      bsState = STATE_ACCEPTED;
      return {
        state: bsState,
        bet_id: payload.bet_id || state.bet_id,
        loading: false,
      };
    }
    if (payload.is_queued) {
      bsState = STATE_QUEUED;
      return {
        state: bsState,
        bet_id: payload.bet_id || state.bet_id,
        queue_delay: parseFloat(payload.queue_delay || '1'),
        loading: false,
      };
    }
    if (payload.is_rejected) {
      bsState = STATE_REJECTED;
    }
    return {
      state: bsState,
      loading: false,
    };
  });
};

export const setBettingSlipError = (payload: ISetBettingSlipErrorPayload) => {
  useBettingSlip.setState(state => {
    return {
      ...state,
      error: payload,
      loading: false,
    };
  });
};

export const startSubmitingBS = () => {
  useBettingSlip.setState(() => ({
    queue_delay: 0,
    loading: true,
  }));
};

export const submitBettingSlip = async (
  payload: ISubmittedBettingslip | IQueuedBettingslip,
) => {
  const { amount } = payload as ISubmittedBettingslip;
  startSubmitingBS();
  const response = await postBettingslip(payload);

  if (response instanceof CustomError) {
    handleSubmitBettingSlipError(response);
  } else {
    if (amount) {
      services.events.emitEvent(MarketingEvents.BET, { amount });
    }
    setBettingSlipSuccess(response.result);
    services.events.emitEvent(BetEvents.PLACE_BET, response.result);
  }
};

export const resetBSpredictions = () => {
  useBettingSlip.setState(state => ({
    selections: {},
    banks: [],
    queue_delay: 0,
    type: IBettingslipType.single,
    totalStake: state.totalStake || defaultState.totalStake,
  }));
};

export const setBettingSlipData = (payload: Partial<IBettingslip>) => {
  useBettingSlip.setState(state => {
    return makeBS(
      state,
      {
        ...state,
        ...payload,
      },
      payload.size || state.size || [1],
    );
  });
};

export const startGetingBetpackerOdds = () => {
  useBettingSlip.setState(() => ({
    loading: true,
  }));
};

export const setBetPackerOdds = (payload: number) => {
  useBettingSlip.setState(() => ({
    betPackerOdds: payload,
    loading: false,
  }));
};

export const setBetPackerPredictions = (payload: string[]) => {
  useBettingSlip.setState(() => ({
    betPackerPredictions: payload,
  }));
};

export const getBetPackerOdds = async (
  payload: IBetPackerOdds,
): Promise<void> => {
  try {
    startGetingBetpackerOdds();
    const response = await fetchBetPackerData(payload);
    if (response instanceof CustomError || response.error) {
      setBetPackerOdds(0);
      if (response?.message) {
        setBettingSlipError(
          (response?.message as ISetBettingSlipErrorPayload) || response?.error,
        );
      }
    } else {
      const odds = response?.result?.joint_odds || 0;
      const predictions = response?.result?.combinable_predictions || [];

      setBetPackerOdds(odds);
      setBetPackerPredictions(map(predictions, pid => String(pid)));
    }
  } catch (e) {
    services.logger?.error(e as string);
  }
};

export const addBetPackerSelection = (payload: ISelection) => {
  useBettingSlip.setState(state => {
    return makeBS(
      state,
      {
        ...state,
        betPackerSelections: {
          ...state.betPackerSelections,
          [payload.id]: {
            ...payload,
            timeAdded: Date.now().toString(),
          },
        },
        banks: [],
        size: state.type === IBettingslipType.system ? [] : state.size,
      },
      [],
    );
  });
};

export const removeBetPackerSelection = (payload: ISelection) => {
  useBettingSlip.setState(state => {
    const newSelections = { ...state.selections };
    delete newSelections[payload.id];

    return makeBS(
      state,
      {
        ...state,
        betPackerSelections: newSelections,
        banks: [],
        size: state.type === IBettingslipType.system ? [] : state.size,
      },
      [],
      BettingSlipActions.REMOVE_SELECTION,
      payload,
    );
  });
};

export const resetBetPackerSelection = () => {
  useBettingSlip.setState(() => ({
    betPackerSelections: {},
  }));
};

export const toggleBSMode = (payload: keyof typeof IBetPackerType) => {
  if (payload === BetView.BETPACKER) {
    resetSelections();
  } else {
    resetBetPackerSelection();
  }
  useBettingSlip.setState(() => ({
    bsMode: payload,
  }));
};

export const resetBetPackerData = (): void => {
  resetBetPackerSelection();
  setBetPackerOdds(0);
  setBetPackerPredictions([]);
  toggleBSMode(BetView.NORMAL);
};

export const toggleBSWalletType = (payload: IBSWalletType): void => {
  useBettingSlip.setState(() => ({
    walletType: payload,
  }));
};
