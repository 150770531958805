import filter from 'lodash/filter';
import includes from 'lodash/includes';
import uniq from 'lodash/uniq';
import reduce from 'lodash/reduce';
import i18next from 'i18next';
import assign from 'lodash/assign';
import keys from 'lodash/keys';
import isEmpty from 'lodash/isEmpty';
import values from 'lodash/values';
import map from 'lodash/map';
import pickBy from 'lodash/pickBy';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import forEach from 'lodash/forEach';

import services from '@features/core/services';

import * as cookies from '@common/constants/cookie';
import numeral from '@common/helpers/numeralHelper';
import {
  IBettingslip,
  IBettingslipType,
  EventListTypes,
  IEventData,
  IBetSelection,
  IOddsComparisonData,
} from '@common/interfaces';
import {
  getSelectionsByEvent,
  checkBettingSlipType,
  getEventsCount,
} from '@common/helpers/bettingSlipHelper/bettingSlipModel';
import {
  calculateLegsCount,
  calculateStake,
} from '@common/helpers/bettingSlipHelper/bettingSlipCalculationModel';
import {
  setBettingSlipData,
  setBettingSlipError,
  setSelections,
  setStake,
  setSelectionsLoading,
  useBettingSlip,
} from '@common/providers/bettingslip/useBettingSlip';
import fetchEventsList from '@common/api/events/fetchEventsList';
import {
  getLiveStatus,
  isMarketEnabled,
} from '@common/helpers/markets/marketModel';
import { hasValidOdds } from '@common/helpers/eventsHelper/predictionModel';
import { ISelection } from '@common/interfaces/prediction/IPrediction';
import { ISelections } from '@common/interfaces/bettingslip/IBettingslip';
import {
  setEventsList,
  updatePredictionsOdds,
  useEventsListState,
} from '@common/providers/events/eventList/useEventsList';
import { ISetBettingSlipErrorPayload } from '@common/providers/bettingslip/types';
import { parseEventList } from '@common/helpers/eventsHelper/eventDataHelper';

export enum BettingSlipActions {
  REMOVE_SELECTION = 'REMOVE_SELECTION',
  ADD_BANK_EVENT = 'ADD_BANK_EVENT',
  REMOVE_BANK_EVENT = 'REMOVE_BANK_EVENT',
  SET_BETTING_SLIP_TYPE = 'SET_BETTING_SLIP_TYPE',
}

export const makeBS = (
  currentBS: IBettingslip,
  newBS: IBettingslip,
  size: number[],
  actionType?: BettingSlipActions,
  payload?: ISelection,
): IBettingslip => {
  const betSize = getEventsCount(currentBS.selections);
  let { banks } = newBS;
  let newSize = [...size];
  const type = checkBettingSlipType(
    newBS,
    betSize === 1 && getEventsCount(newBS.selections) === 2,
  );
  if (type === IBettingslipType.single) {
    newSize = [1];
  } else if (type === IBettingslipType.combi) {
    newSize = [values(getSelectionsByEvent(newBS.selections)).length];
  }
  if (actionType) {
    if (
      actionType === BettingSlipActions.REMOVE_SELECTION &&
      payload &&
      includes(banks, parseFloat(payload.eventId))
    ) {
      banks = filter(banks, e => e !== parseFloat(payload.eventId));
    } else if (
      actionType === BettingSlipActions.REMOVE_SELECTION &&
      type === IBettingslipType.system &&
      newBS.size.length >= 1
    ) {
      newSize = uniq(
        reduce(
          newBS.size,
          (acc, currentSize) => {
            if (
              currentSize >
              values(getSelectionsByEvent(newBS.selections)).length
            ) {
              return acc.concat([currentSize - 1]);
            }
            return acc.concat([currentSize]);
          },
          [] as number[],
        ),
      );
    } else if (
      type === IBettingslipType.system &&
      (actionType === BettingSlipActions.SET_BETTING_SLIP_TYPE ||
        actionType === BettingSlipActions.ADD_BANK_EVENT ||
        actionType === BettingSlipActions.REMOVE_BANK_EVENT)
    ) {
      newSize = [];
    }
  }
  const newState = {
    ...newBS,
    type,
    size: newSize,
    banks,
    legsCount: calculateLegsCount({ ...newBS, type, size: newSize, banks }),
  };

  return {
    ...newState,
    ...calculateStake(
      newState,
      numeral(newState.totalStake).format(`0,0.00`),
      true,
    ),
  };
};
export const handleBSErrorAndOddsUpdate = (
  errorPayload: ISetBettingSlipErrorPayload,
): void => {
  setBettingSlipError(errorPayload);
  updatePredictionsOdds(errorPayload);
};

export const createValidatedSelections = <
  T extends IBetSelection | IOddsComparisonData
>(
  selections: T[],
): ISelections => {
  const keyedByPredictionId = keyBy(selections, selection =>
    'prediction_id' in selection ? selection.prediction_id : selection.pid,
  );

  return mapValues(keyedByPredictionId, selection => ({
    id: 'prediction_id' in selection ? selection.prediction_id : selection.pid,
    marketId: 'market_id' in selection ? selection.market_id : selection.mid,
    eventId: selection.event_id,
    // we don't have categoryId from bet.selections
    categoryId: (selection as IOddsComparisonData)?.category_id || '',
  }));
};

export const getValidatedSelections = (
  selections: ISelections,
  list: IEventData,
): ISelections => {
  return pickBy(selections, selection => !!list.events[selection.eventId]);
};

const adaptSelectionsWithCategory = (
  selections: ISelections,
  list: IEventData,
): ISelections => {
  return mapValues(
    selections,
    (selection: ISelection): ISelection => ({
      ...selection,
      categoryId: list.events[selection.eventId]?.category_id,
    }),
  );
};

export const validateSelections = async (): Promise<void> => {
  const { selections } = useBettingSlip.getState();
  try {
    const eventIds = map(values(selections), 'eventId');
    if (eventIds.length) {
      const list = await fetchEventsList({ events: eventIds });
      // Adapt selections by adding the correct categoryId from list.events
      const adaptedSel = adaptSelectionsWithCategory(selections, list);
      // Validate selections by filtering out those with missing events
      const validatedSelections = getValidatedSelections(adaptedSel, list);
      // After validating the selections, we need to prepare the data for the betslip.
      // The goal is to avoid setting all markets and predictions from the fetched events,
      // and instead only include the specific events, markets, categories, and predictions
      // that correspond to the validated selections.
      const betslipEventData = {
        events: pick(list.events, eventIds),
        markets: pick(
          list.markets,
          map(values(validatedSelections), 'marketId'),
        ),
        categories: pick(
          list.categories,
          map(values(validatedSelections), 'categoryId'),
        ),
        predictions: mapValues(
          pick(list.predictions, keys(validatedSelections)),
          (predictionData, id) => {
            const selection = validatedSelections[id];
            return {
              marketId: predictionData?.market_id || selection.marketId,
              eventId: predictionData?.event_id || selection.eventId,
              categoryId: selection.categoryId,
              ...omit(predictionData, ['market_id', 'event_id']),
            };
          },
        ),
      };

      // Set the events list for betslip
      setEventsList({
        // parse event list to add id prop to out main entetise
        ...parseEventList(betslipEventData),
        listType: EventListTypes.bettingslip,
      });
      // Update betting slip selections
      setBettingSlipData({
        selections: validatedSelections,
      });
      return;
    }
  } catch (e) {
    services.logger.error(e as string);
  } finally {
    setSelectionsLoading(false);
  }
  // If there was an error or no valid selections, clear the betting slip
  setSelections({});
};

export const handleSubmitBettingSlipError = (response): void => {
  if (
    parseFloat(response.code || '') === 2706 ||
    parseFloat(response.code || '') === 2801
  ) {
    validateSelections();
  }

  if (parseFloat(response.code || '') === 2116) {
    // max limits
    const amount =
      response.message.match(/\d+(\.\d+)?/) &&
      response.message.match(/\d+(\.\d+)?/)?.[0];

    setStake({ amount: amount as string });
  }

  if (
    parseFloat(response.code || '') === 2932 ||
    parseFloat(response.code || '') === 2245 ||
    parseFloat(response.code || '') === 2596
  ) {
    response.message = `${i18next.t('bettingslip.err_oasis')}`;
  }

  if (parseFloat(response.code || '') === 2333) {
    response.message = `${i18next.t('bettingslip.err_blocked')}`;
  }

  if (parseFloat(response.code || '') === 1000) {
    response.message = `${i18next.t(
      'bettingslip.too_many_prediction_of_event',
    )}`;
  }
  handleBSErrorAndOddsUpdate(
    assign(response, {
      data: assign({ fromServer: true, ...(response.data || {}) }),
    }),
  );
};

export const saveBettingSlip = (): void => {
  // prettier-ignore
  const { size, totalStake, type, selections, banks } = useBettingSlip.getState();

  try {
    if (!isEmpty(selections)) {
      const betslip = JSON.stringify({
        size,
        totalStake,
        stake: totalStake,
        type,
        banks,
      });
      services.cookie.set(cookies.BETTING_SLIP, betslip);
      services.cookie.remove(cookies.SELECTIONS);
      services.cookie.set(cookies.SELECTIONS, JSON.stringify(selections));
    }
  } catch (e) {
    services.logger.error(e as string);
  }
};

export const restoreBettingSlip = async (): Promise<void> => {
  const savedSlip = services.cookie.get(cookies.BETTING_SLIP);
  const selections = services.cookie.get(cookies.SELECTIONS);
  if (savedSlip) {
    try {
      const parsedSlip = JSON.parse(savedSlip);
      const parsedSelections = JSON.parse(selections || '{}');
      if (!isEmpty(selections) || !isEmpty(parsedSlip)) {
        // toggleSelectionsLoading(true);
        setBettingSlipData({
          selections: parsedSelections,
          selectionsLoading: true,
          ...parsedSlip,
        });
      }
    } catch (e) {
      services.logger.error(e as string);
    }
  }
  setTimeout(() => validateSelections());
  services.cookie.remove(cookies.SELECTIONS);

  if (services.cookie.get(cookies.BETTING_SLIP)) {
    services.cookie.remove(cookies.BETTING_SLIP);
  }
};

export const partitionSelections = (
  selections: ISelections,
): { enabled: ISelections; disabled: ISelections } => {
  const enabled: ISelections = {};
  const disabled: ISelections = {};

  forEach(selections, (selection, key) => {
    const { data } = useEventsListState.getState().betslip;
    const market = data.markets[selection.marketId];
    const prediction = data.predictions[selection.id];

    if (
      isMarketEnabled(market, getLiveStatus(market)) &&
      hasValidOdds(prediction)
    ) {
      enabled[key] = selection;
    } else {
      disabled[key] = selection;
    }
  });

  return { enabled, disabled };
};

export const getSelectionsCount = (selections: ISelections): number =>
  keys(selections).length;
