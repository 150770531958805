import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import join from 'lodash/join';
import split from 'lodash/split';
import some from 'lodash/some';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import filter from 'lodash/filter';

import { betsTypes as types } from '@features/bets/components/myBets';
import { colorSchema } from '@features/core/theming/contexTheme';
import { EventsNames } from '@features/events/components/eventsNames';
import { PageName } from '@features/core/routing/linkAliases';

import formatDate from '@common/helpers/timeHelper/localizedFormat';
import { ButtonType, IBetSelection, StatusCode } from '@common/interfaces';
import { isMatchWebRoutes, setLocation } from '@common/providers/router/helper';
import {
  getCashoutSum,
  getQuote,
  handleCopyBetLink,
} from '@common/helpers/betsHelper';
import { isDesktopView } from '@common/helpers/deviceUtil';
import { useEventsListState } from '@common/providers/events/eventList/useEventsList';
import { setSelectedBetId, useBets } from '@common/providers/bets/useBets';
import {
  getIconNameByStatus,
  handleReUseBet,
} from '@common/helpers/betDetailHelper';
import { isIframeView } from '@common/helpers/appLayoutHelper';
import { formatBalance } from '@common/helpers/paymentsHelper/walletsHelper';
import { useUserState } from '@common/providers/user/useUserState';
import {
  createValidatedSelections,
  getValidatedSelections,
} from '@common/providers/bettingslip/helper';
import { getAffiliateParamsData } from '@common/helpers/userHelper/affiliate/affiliateParams';

import { StyledButton } from '@ui/components/buttons';
import { Icon } from '@ui/components/icon';

import * as S from './BetItem.styled';
import { IBetItem } from './BetItem.types';

const EINZELWETTE_TYPE = '1 Einzelwette';

const isDesktop = isDesktopView();

const BetItem: React.FC<IBetItem> = props => {
  // prettier-ignore
  const { bet, itemStyle, index, isBetDetail, hasReuseBet, isBetDetailView } = props;
  // prettier-ignore
  const { 
    id, cashouts, type, created_ts, status_code = -1, raw_type,
    selections, initial_total_cost, initial_user_risk, total_cost,
    remaining_risk, initial_total_stake, leg_sizes, total_paid_amount
  } = bet;

  const { t } = useTranslation();
  const selectedBetId = useBets(s => s.selectedBetId);
  const formattedType = type === EINZELWETTE_TYPE ? type.slice(2) : type;
  const isUserBetsPage = isMatchWebRoutes(PageName.USER_BETS);
  const hasPendingCashout = some(bet?.cashouts, ['is_payout_done', 0]);
  const list = useEventsListState(stat => stat.cashout.data);
  const eventLoading = useEventsListState(stat => stat.cashout.loading);
  const user = useUserState(state => state.data);
  const [isLoadBetLink, setLoadBetLink] = useState(false);
  const date = formatDate(
    parseInt(`${created_ts || 0}`, 10) * 1000,
    'dd.MM.yy HH:mm',
  );
  const formattedSelections = useMemo(
    () => createValidatedSelections<IBetSelection>(bet?.selections),
    [bet?.selections, list],
  );
  const validatedSelections = useMemo(
    () => getValidatedSelections(formattedSelections, list),
    [formattedSelections, list],
  );

  const reUseBetDisabled = eventLoading || isEmpty(validatedSelections);

  useEffect(() => {
    getAffiliateParamsData();
  }, []);

  const handleExpand = (): null => {
    if (!id) {
      return null;
    }

    if (isDesktop) {
      setSelectedBetId(selectedBetId === id ? '' : id);
      return null;
    }

    setLocation(PageName.USER_BET_ITEM, { id });
    return null;
  };

  const copyBetLink = async (): Promise<void> => {
    const banks = map(filter(selections, { bank: 1 }), item =>
      parseInt(item.event_id, 10),
    );
    try {
      setLoadBetLink(true);
      await handleCopyBetLink({
        selections,
        leg_sizes,
        total_cost,
        type: raw_type,
        banks,
      });
    } finally {
      setLoadBetLink(false);
    }
  };

  const renderStatus = (): JSX.Element | string => {
    switch (status_code) {
      case StatusCode.WON:
        return (
          <S.TextLG>
            {hasPendingCashout ? t(types.pending) : t(types.won)}
          </S.TextLG>
        );
      case StatusCode.CANCELLED:
        return <S.TextLG>{t(types.cancelled)}</S.TextLG>;
      case StatusCode.OPEN:
        return <S.TextLG>{t(types.unsettled)}</S.TextLG>;
      case StatusCode.LOST:
        return <S.TextLG>{t(types.lost)}</S.TextLG>;
      default:
        return '';
    }
  };

  const renderStatusOrWin = (): JSX.Element | string => {
    switch (status_code) {
      case StatusCode.WON:
        return (
          <S.NoWrap>
            <>{renderStatus()}: </>
            {formatBalance(total_paid_amount as string)}
          </S.NoWrap>
        );
      case StatusCode.CANCELLED:
        return <S.TextLG>{t(types.cancelled)}</S.TextLG>;
      case StatusCode.OPEN:
        return <S.TextLG>{t(types.unsettled)}</S.TextLG>;
      case StatusCode.LOST:
        return <S.TextLG>{t(types.lost)}</S.TextLG>;
      default:
        return '';
    }
  };

  const renderIconRight = (): JSX.Element => {
    const { textMain, accent } = colorSchema;
    return isDesktop ? (
      <S.ColMore>
        <Icon name="chevron-right" width="14" height="20" fill={accent} />
      </S.ColMore>
    ) : (
      <S.IconRight
        name="chevron-right"
        fill={textMain}
        width="16"
        height="20"
      />
    );
  };

  const renderReUseBetButton = (): JSX.Element => (
    <S.ReUseBetWrapper>
      <StyledButton
        onClick={(): void | null => {
          return hasReuseBet ? handleReUseBet(bet, validatedSelections) : null;
        }}
        disabled={reUseBetDisabled}
        className="btn"
        dataQa="btn-play-bet"
        label="bets.reuse_bettingslip"
        buttonType={ButtonType.LightGhost}
      />
      {!isIframeView() && (
        <StyledButton
          onClick={copyBetLink}
          disabled={!user?.username || reUseBetDisabled}
          showLoader={isLoadBetLink}
          className="btn"
          dataQa="btn-play-bet"
          label="bets.bettingslip_link"
          buttonType={ButtonType.LightGhost}
        />
      )}
    </S.ReUseBetWrapper>
  );

  const desktopItemInfoView = (): JSX.Element => (
    <>
      <S.DeskData>{date}</S.DeskData>

      <S.DeskTitle className={selectedBetId === id ? 'active' : ''}>
        <S.DeskTitleWrapper>
          <S.GridContainer
            colorByStatus={Boolean(status_code === StatusCode.WON)}
          >
            <S.NoWrap>{formattedType}</S.NoWrap>
          </S.GridContainer>
        </S.DeskTitleWrapper>

        <S.WrapperEventsNames isLongName={isUserBetsPage}>
          {!!selections && <EventsNames selections={selections} />}
        </S.WrapperEventsNames>
      </S.DeskTitle>

      <S.DeskBetDetails>
        <S.DeskBetDetailsWin>
          {bet.is_bonus === '1' && (
            <S.GiftIconDesktop name="gift" width="13" height="16" />
          )}
          {bet.is_shop_emoney === '1' && (
            <S.GiftIconDesktop name="credit-card" width="16" height="16" />
          )}
          <span>{formatBalance(initial_total_cost as string)}</span>
        </S.DeskBetDetailsWin>

        {Number(remaining_risk) !== 0 ? (
          <S.PossibleWinnings>
            {t('common.labels.possible_winnings')}:{' '}
            {formatBalance(remaining_risk as string)}
          </S.PossibleWinnings>
        ) : null}
      </S.DeskBetDetails>

      <S.DeskQuote>
        {getQuote(bet.leg_count, initial_user_risk, initial_total_stake)}
      </S.DeskQuote>

      <S.DeskProfit>
        <S.WinSum colorByStatus={Boolean(status_code === StatusCode.WON)}>
          {formatBalance(total_paid_amount as string)}
        </S.WinSum>

        {cashouts && cashouts.length > 0 && (
          <S.PossibleWinnings>
            {t('bets.cashout')}: {formatBalance(getCashoutSum(cashouts))}
          </S.PossibleWinnings>
        )}
      </S.DeskProfit>

      <S.DeskStatus>
        <S.WinSum colorByStatus={Boolean(status_code === StatusCode.WON)}>
          {renderStatus()}
        </S.WinSum>
      </S.DeskStatus>
    </>
  );

  const mobileItemInfoView = (): JSX.Element => (
    <>
      <S.ColInfo>
        <S.GridContainer
          colorByStatus={Boolean(status_code === StatusCode.WON)}
          isBetDetail
          data-qa="bet-status"
        >
          <S.NoWrap>{formattedType}</S.NoWrap>

          {renderStatusOrWin()}
        </S.GridContainer>

        <S.InfoText className="description">
          <span className="info-block">
            {isDesktop ? join(split(date, ' '), ' - ') : date}
            {' – '}
            {!!selections && <EventsNames selections={selections} />}
          </span>
          {getCashoutSum(cashouts) > 0 ? (
            <span className="cashout-block" data-qa="cashout-block">
              {t('bets.cashout')}: {formatBalance(getCashoutSum(cashouts))}
            </span>
          ) : null}
        </S.InfoText>

        <S.GridContainer isBetDetail>
          <S.PossibleWinnings data-qa="total-stake">
            {bet.is_bonus === '1' && (
              <S.GiftIconDesktop name="gift" width="13" height="16" />
            )}
            {bet.is_shop_emoney === '1' && (
              <S.GiftIconDesktop name="credit-card" width="16" height="16" />
            )}
            <span>
              {t('bets.nls_total')}:{' '}
              {formatBalance(initial_total_cost as string)}
            </span>
          </S.PossibleWinnings>
          {Number(remaining_risk) !== 0 ? (
            <S.PossibleWinnings data-qa="possible-winnings">
              {t('common.labels.possible_winnings')}:{' '}
              {formatBalance(remaining_risk as string)}
            </S.PossibleWinnings>
          ) : null}
        </S.GridContainer>
      </S.ColInfo>
    </>
  );

  return (
    <S.ItemContainer
      onClick={(): boolean | null => !isBetDetail && handleExpand()}
      data-qa="btn-to-bet-detail"
      isEven={index || index === 0 ? !(index % 2) : false}
      style={{ ...itemStyle }}
      className={isDesktop && selectedBetId === id ? 'active' : ''}
      id="btn-to-bet-detail"
    >
      <S.Container role="presentation" isBetDetail={isBetDetail}>
        {!isBetDetail && (
          <S.StatusSquare colorSquare={status_code}>
            <Icon
              width="12"
              height="12"
              name={getIconNameByStatus(status_code)}
            />
          </S.StatusSquare>
        )}

        {isDesktop && !isBetDetailView
          ? desktopItemInfoView()
          : mobileItemInfoView()}
        {!isBetDetail && renderIconRight()}
      </S.Container>

      {isBetDetail && !reUseBetDisabled && renderReUseBetButton()}
    </S.ItemContainer>
  );
};

export default BetItem;
