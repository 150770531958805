import React, { useEffect, useRef } from 'react';
import reduce from 'lodash/reduce';
import { useTranslation } from 'react-i18next';

import { useServices } from '@features/core/services';
import {
  comparePageWithCurrentLocation,
  getStaticPageURL,
  PageName,
  StaticSlugPageName,
} from '@features/core/routing/linkAliases';
import { TransactionsSummary } from '@features/user/components/summaryLayers/transactionsSummary';

import { SUMMARY_LAYER } from '@common/constants/dialogs';
import { SUMMARY_LAYER_COOKIE } from '@common/constants/cookie';
import {
  ButtonSize,
  ButtonType,
  SidebarSide,
  TTimeout,
} from '@common/interfaces';
import { isDesktopView } from '@common/helpers/deviceUtil';
import deleteTimeout from '@common/helpers/timeoutHelper';
import fetchStatisticalData from '@common/api/account/fetchStatisticalData';
import { closeSidebar } from '@common/providers/application/useAppState';
import { resetTransactions } from '@common/providers/transactions/useTransactions';
import getTransactionsAction from '@common/helpers/transactions/getTransactionsAction';
import { usePaymentsState } from '@common/providers/payments/usePayments';
import { resetLimits } from '@common/providers/limits/useLimits';
import {
  resetSummary,
  useCasinoState,
} from '@common/providers/casino/useCasino';
import { getSummaryAction } from '@common/providers/casino/helper';
import { formatBalance } from '@common/helpers/paymentsHelper/walletsHelper';
import { useIsLoggedIn } from '@common/helpers/userHelper/loggedInState';

import { StyledButton } from '@ui/components/buttons';

import * as S from './SummaryLayers.styled';

const AccountSummary = (): React.ReactElement | null => {
  const { t } = useTranslation();
  const { cookie } = useServices();
  const sportsSummary = useCasinoState(s => s.sports_summary.data?.result);
  const sportsSummaryLoading = useCasinoState(s => s.sports_summary.loading);
  const timeoutRef = useRef<TTimeout>(null);
  const isLoggedIn = useIsLoggedIn();
  const account = usePaymentsState(s => s.account);
  const isUserOverview = comparePageWithCurrentLocation(PageName.USER_OVERVIEW);

  const summaryData = [
    {
      label: t('user.summary.deposits'),
      value: formatBalance(sportsSummary?.deposit_total),
    },
    {
      label: t('user.summary.payouts'),
      value: formatBalance(sportsSummary?.withdrawal_total),
    },
    {
      label: t('user.summary.bets'),
      value: formatBalance(sportsSummary?.sports_amount_user_won),
    },
    {
      label: t('user.summary.total'),
      value: formatBalance(sportsSummary?.sports_amount_user_turnover),
    },
  ];

  const handleClose = (): void => {
    closeSidebar(SUMMARY_LAYER);
    cookie.set(SUMMARY_LAYER_COOKIE, SUMMARY_LAYER_COOKIE, { expires: null });
  };

  const keyPressHandler = (event: KeyboardEvent): void | undefined => {
    if (event.keyCode === 13) {
      handleClose();
    }
  };

  useEffect(() => {
    if (account?.token) {
      fetchStatisticalData();
    }
  }, [account?.token]);

  useEffect(() => {
    return () => {
      deleteTimeout(timeoutRef.current);

      if (isLoggedIn) {
        resetTransactions();
        resetLimits();

        if (isUserOverview) {
          getTransactionsAction({ offset: 0, type: 'all', max_age_days: 90 });
        }
      }

      resetSummary('sports_summary');
    };
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      getSummaryAction('sports_summary');
      getTransactionsAction({ offset: 0, type: 'all', max_age_days: 30 });
    }
  }, [cookie]);

  useEffect(() => {
    window.addEventListener('keydown', keyPressHandler);

    return () => {
      window.removeEventListener('keydown', keyPressHandler);
    };
  }, []);

  const linkURL = getStaticPageURL(
    PageName.RESPONSIBILITY,
    StaticSlugPageName.RESPONSIBLE_GAMING,
  );

  return (
    <S.Root className="account-summary">
      <S.HeaderWrapper>
        <S.SummaryHeader>{t('user.summary.header')}</S.SummaryHeader>
      </S.HeaderWrapper>
      <S.Content>
        {sportsSummaryLoading ? (
          <S.Loader>{t('common.labels.loading')}</S.Loader>
        ) : (
          <>
            <S.AmountData withBorder>
              {reduce(
                summaryData,
                (acc, item, idx) => {
                  return acc.concat(
                    <S.AmountRow
                      key={item.label}
                      data-qa={`account-summary-label-${idx}`}
                    >
                      <S.Label>{item.label}:</S.Label>
                      {item.value}
                    </S.AmountRow>,
                  );
                },
                [] as JSX.Element[],
              )}
            </S.AmountData>
            <S.LineDivider />
            <S.AmountData>
              <S.AmountRow className="total">
                <S.Label className="sum" data-qa="account-summary-profit-loss">
                  {t('user.summary.profit_loss')}
                </S.Label>
                {formatBalance(sportsSummary?.sports_amount_user_profit_lost)}
              </S.AmountRow>
            </S.AmountData>
          </>
        )}
        <S.WarningMessage>
          <S.InfoData>{t('user.summary.warning_message_1')}</S.InfoData>
          <S.InfoData>
            {t('user.summary.warning_message_2')}
            <S.Link
              href={linkURL}
              ariaLabel={t('common.links.responsibility')}
              onClick={handleClose}
            >
              {t('user.summary.warning_message_2_underline_text')}
            </S.Link>
          </S.InfoData>
        </S.WarningMessage>
      </S.Content>
      <TransactionsSummary />
      <S.ButtonContainer>
        <StyledButton
          onClick={handleClose}
          label="user.summary.submit_btn"
          size={ButtonSize.Big}
          dataQa="accountSummary-btn-submit"
          buttonType={ButtonType.Secondary}
        />
      </S.ButtonContainer>
    </S.Root>
  );
};

AccountSummary.meta = {
  side: isDesktopView() ? SidebarSide.center : SidebarSide.bottom,
  key: SUMMARY_LAYER,
  fullScreen: true,
};

export { AccountSummary as Component };

export default AccountSummary;
