export const ENV = 'ENV';
export const PROXY_URL = 'proxyUrl';
export const DEPOSIT_LIMITS = 'depositLimits';
export const PROXY_PORT = 'proxyPort';
export const GRAPH_URL = 'graphUrl';
export const HAS_GRAPHQL_CACHE = 'hasGraphqlCache';
export const SOCKET_CLUSTER_URL = 'socketClusterUrl';
export const SOCKET_URL = 'socketUrl';
export const BET_RM_SOCKET_URL = 'betRmSocketUrl';
export const WALLET_UPDATER_HOST = 'wallet_updater_host';
export const WALLET_UPDATER_PORT = 'wallet_updater_port';
export const WALLET_UPDATER_USER = 'wallet_updater_user';
export const WALLET_UPDATER_PASS = 'wallet_updater_pass';
export const EVENT_SERVICE_URL = 'eventServiceUrl';
export const HAS_HOME_PAGE = 'hasHomePage';
export const HAS_CASHOUT_FAVORITES = 'hasCashoutFavorites';
export const HAS_CATEGORIES_FAVORITES = 'hasCategoriesFavorites';
export const HAS_AUTO_WITHDRAWAL = 'hasAutoWithdrawal';
export const HAS_CASINO = 'hasCasino';
export const HAS_GDPR = 'hasGdpr';
export const HAS_UPLOADS = 'hasUploads';
export const HAS_LMT_PAGE = 'hasLMTPage';
export const HAS_BET_BUILDER = 'hasBetPacker';
export const HAS_TICKET_SCANNER = 'hasTicketScanner';
export const HAS_MANUAL_BARCODE = 'hasManualBarcode';
export const HAS_TEST_LIST_MODAL = 'hasTestListModal';
export const HAS_FULL_REGISTRATION = 'hasFullRegistration';
export const HAS_CHECK_MISS_USER_DATA = 'hasCheckMissUserData';
export const HAS_STATIC_PAGES = 'hasStaticPages';
export const HAS_LIMITS = 'hasLimits';
export const HAS_DEPOSITS = 'hasDeposits';
export const HAS_WITHDRAWALS = 'hasWithdrawals';
export const HAS_EVENT_MARKETS = 'hasEventMarkets';
export const HAS_TOTAL_ODDS_NOTIFIER = 'hasTotalOddsNotifier';
export const HAS_FAVORITES = 'hasFavorites';
export const HAS_ALARMS = 'hasAlarms';
export const HAS_ALARM_REASONS = 'hasAlarmReasons';
export const HAS_SPORTRADAR_WIDGET_LMT = 'hasSportradarWidgetLMT';
export const HAS_SPORTRADAR_WIDGET_TABLE = 'hasSportradarWidgetTable';
export const HAS_SPORTRADAR_WIDGET_LIGA_TABLE = 'hasSportradarWidgetLigaTable';
export const HAS_LAST_MATCHES = 'hasSportradarWidgetLastMatches';
export const HAS_SPORTRADAR_IFRAME_STATISTICS = 'hasSportradarIframeStatistics';
export const HAS_RESULTS = 'hasResults';
export const HAS_REGISTRATION = 'hasRegistration';
export const HAS_INBOX = 'hasInbox';
export const HAS_DROPDOWN_MARKETS = 'hasDropDownMarkets';
export const THEME = 'theme';
export const SAFECHARGE_API_DEPOSITS = 'safechargeApiDeposits';
export const CAPTCHA_KEY = 'captchaKey';
export const HAS_CAPTCHA = 'hasCaptcha';
export const MARKETING_COOKIES = 'marketingCookie';
export const DEFAULT_LANGUAGE = 'defaultLanguage';
export const ALLOWED_LANGUAGES = 'allowedLanguages';
export const SHOW_REMAINING_TIMERS = 'showRemainingTimers';
export const AVS_API_URL = 'avsApiUrl';
export const AVS_RESOURCE_URL = 'avsResourceUrl';
export const HAS_LUGAS = 'hasLugas';
export const HAS_GLOBAL_LIMITS = 'hasGlobalLimits';
export const HAS_LUGAS_STRICT = 'hasLugasStrict';
export const HAS_NEWS = 'hasNews';
export const HAS_TEST_PAGE = 'hasTestPage';
export const HAS_WALLET_REQUEST = 'hasWalletRequest';
export const HAS_ANIMATIONS = 'hasAnimations';
export const HAS_SUSPEND_REASON = 'hasSuspendReason';
export const HAS_SOCKET_CLUSTER = 'hasSocketCluster';
export const HAS_CREATE_ONLINE_ACCOUNT = 'hasCreateOnlineAccount';
export const HAS_YEARLY_DATA_CHECK = 'hasYearlyDataCheck';
export const LIVE_COUNTERS = 'live_counters';
export const HAS_EVENT_DETAIL_POPUP = 'hasEventDetailPopup';
export const HAS_BONUS_WALLETS = 'hasBonusWallets';
export const HAS_BONUS_CANCELLATION = 'hasBonusCancellation';
export const HAS_BONUS_CODES = 'hasBonusCodes';
export const COLLAPSED_CATS = 'collapsedCats';
export const TEST_USERS = 'testUsers';
export const HAS_SUMMARY_LAYER = 'hasSummaryLayer';
export const HAS_SMART_APP_BANNER = 'hasSmartAppBanner';
export const HAS_PROMOTIONS = 'hasPromotions';
export const HAS_SLIDERS = 'hasSliders';
export const HAS_OASIS = 'hasOasis';
export const HAS_PARTNERS_BOX = 'hasPartnersBox';
export const BETTINGSLIP_DEFAULT_STATE = 'bettingslipDefaultState';
export const MARKET_MATRIX = 'market_matrix';
export const HAS_PUSH_NOTIFICATION = 'hasPushNotification';
export const EXCLUDE_MARKETS = 'excludeMarkets';
export const HAS_COOKIE_CONSENT_TYPE = 'hasCookieConsentType';
export const HAS_BIOMETRICS_AUTOLOGIN = 'hasBiometricsAutologin';
export const REALITY_PERIOD = 'realityPeriod';
export const HAS_ODDS_COMPARISON = 'hasOddsComparison';
export const ODDS_COMPARISON_PROVIDERS = 'oddsComparisonProviders';
export const SHOW_NUMERIC_EVENT_CODE_IN_EVENT_DETAIL =
  'showNumericEventCodeInEventDetail';
export const NOTIFICATION_ENDPOINT = 'notificationEndpoint';
export const SHOW_DEPOSIT_LIMIT_FOR_ALL = 'showDepositLimitForAll';
export const HAS_GOOGLE_TAG_MANAGER = 'hasGoogleTagManager';
export const HAS_FACEBOOK_PIXEL = 'hasFBPixel';
export const HAS_JSONRPC_AUTH = 'hasJSONRPCAuth';
export const MAX_BS_EVENTS = 'maxBSEvents';
export const MAX_BS_SELECTIONS = 'maxBSSelections';
export const GCMS_PROXY_URL = 'gcmsProxyUrl';
export const IS_EMBEDDED_SOLUTION = 'isEmbeddedSolution';
export const USE_NEW_SCAN_BET_API = 'useNewScanBetApi';
export const DEFAULT_ROUTE = 'defaultRoute';
export const HAS_MY_FAVORITO = 'hasMyFavorito';
export const HAS_BETRADAR_ANALYTICS = 'hasBetradarAnalytics';
export const HAS_SHOP_E_MONEY = 'hasShopEMoney';
export const HAS_CASHOUT = 'hasCashout';
export const HAS_MY_BETS = 'hasMyBets';
export const HAS_LITTLE_CASHOUT = 'hasLittleCashout';
export const IS_TERMINAL = 'isTerminal';
export const HAS_ODDS_BOOST = 'hasOddsBoost';
export const HAS_NEW_CC_PAYMENT_ROUTE = 'hasNewCCPaymentRoute';

export type ConfigConstantsT =
  | typeof ENV
  | typeof PROXY_URL
  | typeof DEPOSIT_LIMITS
  | typeof PROXY_PORT
  | typeof GRAPH_URL
  | typeof HAS_GRAPHQL_CACHE
  | typeof SOCKET_CLUSTER_URL
  | typeof SOCKET_URL
  | typeof BET_RM_SOCKET_URL
  | typeof WALLET_UPDATER_HOST
  | typeof WALLET_UPDATER_PORT
  | typeof WALLET_UPDATER_USER
  | typeof WALLET_UPDATER_PASS
  | typeof EVENT_SERVICE_URL
  | typeof HAS_HOME_PAGE
  | typeof HAS_CASHOUT_FAVORITES
  | typeof HAS_CATEGORIES_FAVORITES
  | typeof HAS_AUTO_WITHDRAWAL
  | typeof HAS_CASINO
  | typeof HAS_BONUS_WALLETS
  | typeof HAS_BONUS_CODES
  | typeof HAS_GDPR
  | typeof HAS_UPLOADS
  | typeof HAS_LMT_PAGE
  | typeof HAS_BET_BUILDER
  | typeof HAS_TICKET_SCANNER
  | typeof HAS_MANUAL_BARCODE
  | typeof HAS_TEST_LIST_MODAL
  | typeof HAS_FULL_REGISTRATION
  | typeof HAS_CHECK_MISS_USER_DATA
  | typeof HAS_STATIC_PAGES
  | typeof HAS_LIMITS
  | typeof HAS_DEPOSITS
  | typeof HAS_WITHDRAWALS
  | typeof HAS_EVENT_MARKETS
  | typeof HAS_TOTAL_ODDS_NOTIFIER
  | typeof HAS_FAVORITES
  | typeof HAS_ALARMS
  | typeof HAS_ALARM_REASONS
  | typeof HAS_SPORTRADAR_WIDGET_LMT
  | typeof HAS_SPORTRADAR_WIDGET_TABLE
  | typeof HAS_SPORTRADAR_WIDGET_LIGA_TABLE
  | typeof HAS_LAST_MATCHES
  | typeof HAS_SPORTRADAR_IFRAME_STATISTICS
  | typeof HAS_RESULTS
  | typeof HAS_REGISTRATION
  | typeof HAS_INBOX
  | typeof HAS_DROPDOWN_MARKETS
  | typeof THEME
  | typeof SAFECHARGE_API_DEPOSITS
  | typeof CAPTCHA_KEY
  | typeof HAS_CAPTCHA
  | typeof MARKETING_COOKIES
  | typeof DEFAULT_LANGUAGE
  | typeof ALLOWED_LANGUAGES
  | typeof SHOW_REMAINING_TIMERS
  | typeof AVS_API_URL
  | typeof AVS_RESOURCE_URL
  | typeof HAS_LUGAS
  | typeof HAS_GLOBAL_LIMITS
  | typeof HAS_LUGAS_STRICT
  | typeof HAS_NEWS
  | typeof HAS_TEST_PAGE
  | typeof HAS_WALLET_REQUEST
  | typeof HAS_ANIMATIONS
  | typeof HAS_SUSPEND_REASON
  | typeof HAS_SOCKET_CLUSTER
  | typeof HAS_CREATE_ONLINE_ACCOUNT
  | typeof HAS_YEARLY_DATA_CHECK
  | typeof LIVE_COUNTERS
  | typeof HAS_EVENT_DETAIL_POPUP
  | typeof HAS_BONUS_CANCELLATION
  | typeof HAS_BONUS_CODES
  | typeof COLLAPSED_CATS
  | typeof TEST_USERS
  | typeof HAS_SUMMARY_LAYER
  | typeof HAS_SMART_APP_BANNER
  | typeof HAS_SLIDERS
  | typeof HAS_PROMOTIONS
  | typeof HAS_OASIS
  | typeof HAS_PARTNERS_BOX
  | typeof BETTINGSLIP_DEFAULT_STATE
  | typeof MARKET_MATRIX
  | typeof HAS_PUSH_NOTIFICATION
  | typeof EXCLUDE_MARKETS
  | typeof HAS_BIOMETRICS_AUTOLOGIN
  | typeof REALITY_PERIOD
  | typeof EXCLUDE_MARKETS
  | typeof HAS_COOKIE_CONSENT_TYPE
  | typeof HAS_ODDS_COMPARISON
  | typeof ODDS_COMPARISON_PROVIDERS
  | typeof NOTIFICATION_ENDPOINT
  | typeof SHOW_DEPOSIT_LIMIT_FOR_ALL
  | typeof HAS_GOOGLE_TAG_MANAGER
  | typeof HAS_FACEBOOK_PIXEL
  | typeof HAS_JSONRPC_AUTH
  | typeof MAX_BS_EVENTS
  | typeof MAX_BS_SELECTIONS
  | typeof GCMS_PROXY_URL
  | typeof SHOW_NUMERIC_EVENT_CODE_IN_EVENT_DETAIL
  | typeof IS_EMBEDDED_SOLUTION
  | typeof USE_NEW_SCAN_BET_API
  | typeof HAS_MY_FAVORITO
  | typeof DEFAULT_ROUTE
  | typeof HAS_BETRADAR_ANALYTICS
  | typeof HAS_SHOP_E_MONEY
  | typeof HAS_MY_BETS
  | typeof HAS_CASHOUT
  | typeof HAS_LITTLE_CASHOUT
  | typeof IS_TERMINAL
  | typeof HAS_ODDS_BOOST
  | typeof HAS_NEW_CC_PAYMENT_ROUTE;
